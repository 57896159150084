<template>
  <div class="home">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <div class="header_title">
            <div class="deviceBack">
              <i class="el-icon-arrow-left" @click="back()"></i>
            </div>
            <span>公告详情-佛山市灏海餐饮管理有限公司</span>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="announceDetail">
          <div class="detailTil">{{ dataForm.title }}</div>
          <div class="detailAuther">
            <div class="HalfName">作者: {{ dataForm.author }}</div>
            <div class="HalfName">时间: {{ dataForm.publishTime }}</div>
          </div>
          <div class="detailMain" v-html="dataForm.content"></div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataForm: {
        id: 0,
        title: "",
        author: "",
        publishTime: "",
        content: "",
        readCount: 0,
        isShow: 1,
        isIndexShow: 0,
        indexImageUrl: null,
        description: "",
        sortNum: 0
      },
    }
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      let id = parseInt(this.$route.query.id);
      this.dataForm.id = id;
      this.$http.httpGet(`/cms/appAnnouncement/info/${id}`).then( data => {
        if (data && data.code === 0) {
          this.dataForm = {...data.appAnnouncement}
        }
      });
    },
    // 返回上一页
    back() {
      this.$router.replace({name: "announce"});
    },
  },
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  .el-container {
    height: 100%;
    .el-main{
      padding: 0 !important;
      .announceDetail {
        position: relative;
        padding: 15px;
        .detailTil {
          font-size: 16px;
          padding: 0 0 20px;
          font-weight: 600;
          line-height: 1.4;
          letter-spacing: 1px;
          text-align: center;
        }
        .detailAuther {
          position: relative;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          padding: 0 0 15px;
          .HalfName {
            flex: 1;
            color: #666;
            font-size: 13px;
            text-align: center;
          }
        }
        .detailMain ::v-deep {
          h1, h2, h3, h4, h5 {
            font-weight: 600;
            color: #333;
            font-size: 24px;
            line-height: 1.6;
          }
          h2 {
            font-size: 22px;
          }
          h3 {
            font-size: 20px;
          }
          h4 {
            font-size: 18px;
          }
          h5 {
            font-size: 16px;
          }
          p {
            font-size: 14px;
            color: #666;
            text-indent: 2em;
            line-height: 1.6;
          }
        }
      }
    }
  }
}
</style>
